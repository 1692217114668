export const QAList = {
  q1: {
    q: "请问您是有意捐献肾脏的捐献者还是需要肾移植手术的患者？",
    a_list: [
      {
        a: "捐献者",
        result: "q2",
      },
      {
        a: "患者",
        result: "q02",
      },
    ],
  },
  q2: {
    q: "请问您是否年满18周岁且具有完全民事行为能力？",
    a_list: [
      {
        a: "是",
        result: "q3",
      },
      {
        a: "否",
        result: "r1",
      },
    ],
  },
  q3: {
    q: "请问您与接受肾脏捐献的患者是什么关系？",
    a_list: [
      {
        a: "直系血亲：父母、子女",
        result: "r2",
      },
      {
        a: "其他直系血亲：祖父母、外祖父母、孙子（女）、外孙子（女）",
        result: "r3",
      },
      {
        a: "三代内的旁系血亲",
        result: "q6",
      },
      {
        a: "夫妻",
        result: "q4",
      },
      {
        a: "其他",
        result: "r5",
      },
    ],
  },
  q4: {
    q: "请问您与您的爱人结婚几年了？",
    a_list: [
      {
        a: "三年及三年以上",
        result: "r6",
      },
      {
        a: "三年以下",
        result: "q5",
      },
    ],
  },
  q5: {
    q: "您与您的配偶是否婚后育有子女？",
    a_list: [
      {
        a: "是",
        result: "r6",
      },
      {
        a: "否",
        result: "r7",
      },
    ],
  },
  q6: {
    q: "请选择关系",
    a_list: [
      {
        a: "兄弟姐妹",
        remark: "包括同父同母的兄弟姐妹、同父异母或同母异父的兄弟姐妹",
        result: "r4",
      },
      {
        a: "父母的兄弟姐妹",
        remark: "即你的舅舅、姑姑、叔叔、阿姨。",
        result: "r4",
      },
      {
        a: "堂兄弟姐妹",
        remark:
          "你父母的兄弟姐妹的子女，即你父亲的兄弟的子女（堂兄弟姐妹）和你父亲的姐妹的子女（堂兄弟姐妹）",
        result: "r4",
      },
      {
        a: "表兄弟姐妹",
        remark:
          "你父母的兄弟姐妹的子女，即你母亲的兄弟的子女（表兄弟姐妹）和你母亲的姐妹的子女（表兄弟姐妹）",
        result: "r4",
      },
      {
        a: "侄子侄女、外甥外甥女",
        remark: "你的兄弟姐妹的子女",
        result: "r4",
      },
      {
        a: "伯父、叔父、姑母、舅父、姨母",
        remark: "你父母的兄弟姐妹的配偶",
        result: "r4",
      },
    ],
  },
  q02: {
    q: "是否有亲人愿意为您捐献肾脏？",
    a_list: [
      {
        a: "是",
        result: "q03",
      },
      {
        a: "否",
        result: "r01",
      },
    ],
  },
  q03: {
    q: "他/她是否已经年满18周岁",
    a_list: [
      {
        a: "是",
        result: "q04",
      },
      {
        a: "否",
        result: "r02",
      },
    ],
  },
  q04: {
    q: "3.有意捐献肾脏者和您的关系是？",
    a_list: [
      {
        a: "直系血亲：父母、子女",
        result: "r03",
      },
      {
        a: "其他直系血亲：祖父母、外祖父母、孙子（女）、外孙子（女）",
        result: "r04",
      },
      {
        a: "三代内的旁系血亲",
        result: "q07",
      },
      {
        a: "夫妻",
        result: "q05",
      },
      {
        a: "其他",
        result: "r06",
      },
    ],
  },
  q05: {
    q: "您与您的爱人结婚几年了",
    a_list: [
      {
        a: "三年及三年以上",
        result: "r07",
      },
      {
        a: "三年以下",
        result: "q06",
      },
    ],
  },
  q06: {
    q: "您与您的爱人是否婚后育有子女",
    a_list: [
      {
        a: "是",
        result: "r07",
      },
      {
        a: "否",
        result: "r08",
      },
    ],
  },
  q07: {
    q: "请选择关系",
    a_list: [
      {
        a: "兄弟姐妹",
        remark: "包括同父同母的兄弟姐妹、同父异母或同母异父的兄弟姐妹",
        result: "r05",
      },
      {
        a: "父母的兄弟姐妹",
        remark: "即你的舅舅、姑姑、叔叔、阿姨。",
        result: "r05",
      },
      {
        a: "堂兄弟姐妹",
        remark:
          "你父母的兄弟姐妹的子女，即你父亲的兄弟的子女（堂兄弟姐妹）和你父亲的姐妹的子女（堂兄弟姐妹）",
        result: "r05",
      },
      {
        a: "表兄弟姐妹",
        remark:
          "你父母的兄弟姐妹的子女，即你母亲的兄弟的子女（表兄弟姐妹）和你母亲的姐妹的子女（表兄弟姐妹）",
        result: "r05",
      },
      {
        a: "侄子侄女、外甥外甥女",
        remark: "你的兄弟姐妹的子女",
        result: "r05",
      },
      {
        a: "伯父、叔父、姑母、舅父、姨母",
        remark: "你父母的兄弟姐妹的配偶",
        result: "r05",
      },
    ],
  },
  r1: {
    type: "fail",
    r: "很抱歉，您目前无法进行捐献肾脏的行为。很理解您对亲人的关心与爱护，但根据《人体器官移植技术临床应用管理规定》，只有年满18岁且具有完全民事行为能力的公民才可以进行器官捐献，请您在成年且具有完全民事行为能力情况下再进行考虑。",
  },
  r2: {
    type: "success",
    r: "您符合亲属供肾的基本要求哦！无论是父母之爱子女还是子女之爱父母，都是世上最伟大的情感之一。但也请您做好相关检查，肾脏移植不仅要关系够近，还要肾脏和身体配合良好，现在还有很长的路要走，祝愿您的家人早日康复。",
  },
  r3: {
    type: "success",
    r: "您符合亲属供肾的基本要求，但不能掉以轻心，您的肾脏是否状态良好、与您的亲人是否适配都是未知数，要做好相关检查，祝愿您能成为拯救家人的“超级英雄”。",
  },
  r4: {
    type: "success",
    r: "按照最新的《人体器官移植技术临床应用管理规定》，您是符合器官捐献的基本要求的。但能否器官捐献不仅取决于自己的意愿，还要通过伦理委员会的具体评估，此外您有捐献器官的资格后，还需要验证您的肾脏是否匹配，肾路漫漫，祝愿您的家人能够早日康复。",
  },
  r5: {
    type: "fail",
    r: "很抱歉，您无法向预想中的那位患者捐献肾脏，很羡慕你们的深厚情谊，让您能为了他/她捐献自己的肾脏，但根据《人体器官移植技术临床应用管理规定》，活体器官的接受人限于活体器官捐献人的配偶、直系血亲或者三代以内旁系血亲。",
  },
  r6: {
    type: "success",
    r: "您可以为您的爱人捐献肾脏！作为我国法律框架下唯一可行的非血缘关系的亲属活体肾移植，您愿意为您爱人捐献的行为值得称赞，但也要请您做好相关检查，希望不仅您与爱人在感情上匹配，您的肾脏也能与他/她的身体匹配，衷心祝愿您的爱人早日康复。",
  },
  r7: {
    type: "fail",
    r: "虽然很羡慕您与爱人的感情，但根据《人体器官移植技术临床应用管理规定》，您并不符合肾脏捐献的条件，只有婚后育有子女或结婚三年以上者才可以进行肾脏捐献。也请您不要灰心，并非只有亲属肾移植一条治疗途径，和您的爱人调整好心态接受其他治疗，也可能会有很好的治疗效果！",
  },
  r01: {
    type: "fail",
    r: "很遗憾，但目前我国活体器官的接受人限于活体器官捐献人的配偶、直系血亲或者三代以内旁系血亲。您可以与医生积极沟通，寻找其他治疗方式或者等待其他方式供肾。",
  },
  r02: {
    type: "fail",
    r: "很抱歉，您无法接受他/她的肾脏捐献。很理解您亲人对您的关心与爱护，但根据《人体器官移植技术临床应用管理规定》，只有年满18岁且具有完全民事行为能力的公民才可以进行器官捐献，未成年器官捐献并不可取！",
  },
  r03: {
    type: "success",
    r: "您是可以接受来自父母与子女的肾脏捐献的哦！无论是父母之爱子女还是子女之爱父母，都是世上最伟大的情感之一。但也请您与家人做好相关检查，肾脏移植不仅要关系够近，还要肾脏和身体配合良好，现在还有很长的路要走，祝愿您早日康复。",
  },
  r04: {
    type: "success",
    r: "您与有意向捐献肾脏的亲属符合亲属供肾的基本要求，但不能掉以轻心，您亲人的肾脏是否状态良好、与您的身体是否适配都是未知数，要做好相关检查，希望您的身体也能接受来自亲人的爱。",
  },
  r05: {
    type: "success",
    r: "按照最新的《人体器官移植技术临床应用管理规定》，您的亲人是符合器官捐献的基本要求的。但能否器官捐献不仅取决于他/她的意愿，还要通过伦理委员会的具体评估，此外，还需要验证您是否与捐献者的肾脏匹配，肾路漫漫，祝愿您能够早日康复。",
  },
  r06: {
    type: "fail",
    r: "很抱歉，您是不能接受他/她的肾脏捐献的，很羡慕你们的深厚情谊，不论是情侣、朋友、亲人还是其他关系，能为你捐献肾脏都是很值得敬佩的行为，但根据《人体器官移植技术临床应用管理规定》，活体器官的接受人限于活体器官捐献人的配偶、直系血亲或者三代以内旁系血亲。还希望您与捐献者能够理解",
  },
  r07: {
    type: "success",
    r: "您可以接受您的爱人为您捐献的肾脏！夫妻间的亲属肾移植是我国法律框架下唯一可行的非血缘关系的亲属活体肾移植，也要请她/他做好相关检查，希望不仅您与爱人在感情上匹配，她/他的肾脏也能与您的身体匹配，衷心祝愿您早日康复，你们的生活健康美满。",
  },
  r08: {
    type: "fail",
    r: "虽然很羡慕您与爱人的感情，但根据《人体器官移植技术临床应用管理规定》，您并不是您的爱人可以进行肾脏捐献的对象，只有婚后育有子女或结婚三年以上者才可以进行肾脏捐献。也请您不要灰心，并非只有亲属肾移植一条治疗途径，您要调整好心态接受其他治疗，也可能会有很好的治疗效果！",
  },
};
